<template>
  <div
    class="cotainer"
    v-loading="loading">
    <el-carousel class="banner">
      <div v-if="banner.length > 0">
        <el-carousel-item
          v-for="item in banner"
          :key="item.id">
          <img
            :src="item.url"
            style="
              height: 100%;
              width: 100%;
              object-fit: cover;
              cursor: pointer;
            "
            @click="toDetail(item)" />
        </el-carousel-item>
      </div>
      <img
        :src="defaultBanner"
        style="height: 100%; width: 100%; object-fit: cover"
        v-else />
    </el-carousel>
    <div class="m_b_20 flex_justify_around box">
      <div class="tabs">
        <div
          class="item"
          v-for="(item, index) in items"
          :key="item.id"
          :class="defaultItem == index ? 'active' : ''"
          @click="handleSelect(item, index)">
          <div>
            <div class="title">{{ item.name }}</div>
            <div class="total">
              <div class="total">共{{ item.total ? item.total : 0 }}条</div>
            </div>
          </div>
        </div>
      </div>
      <div class="contents">
        <div class="list">
          <div
            v-for="item in contents.records"
            :key="item.id"
            @click="
              $router.push({
                path: '/party/content',
                query: { from: 6, cid: item.id, id: $route.query.id },
              })
            ">
            <div class="title fs_16">{{ item.title }}</div>
            <div style="opacity: 0.6">{{ item.createTime.split(' ')[0] }}</div>
          </div>
        </div>
        <div class="paging">
          <el-pagination
            @current-change="handleCurrentChange"
            :page-size="parameter.size"
            :background="true"
            layout="total, prev, pager, next"
            :total="contents.total"></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getPartyBanner,
  getPartyResources,
  getContents,
  getCountResources,
} from '@/api/party/index';
import defaultBanner from '@/assets/image/Group 1021.png';
export default {
  name: 'politicalLifeDetail',
  data: () => {
    return {
      banner: [],
      defaultBanner,
      defaultItem: 0,
      items: [],
      parameter: {
        contentId: '',
        current: 1,
        size: 10,
        isExamine: 1,
      },
      contents: [],
      loading: false,
      totals: [],
    };
  },
  components: {},
  created() {
    this.loading = true;
    //获取轮播banner
    getPartyBanner({
      type: 2,
      status: 1,
    }).then((res) => {
      this.banner = res.data;
    });

    /* 党风廉政*/
    getContents({
      id: this.$route.query.id,
    }).then((res) => {
      let data = res.data[0].children;
      this.parameter.contentId = res.data[0].children[0].id;

      getCountResources({
        contentId: res.data[0].children.map((item) => item.id).toString(),
      }).then((res) => {
        data.forEach((item) => {
          res.data.forEach((item1) => {
            if (item.id == item1.content_id) {
              item.total = item1.countResources;
            }
          });
        });
        this.items = data;
      });
      this.getPage();
    });
  },
  methods: {
    async getPage() {
      this.loading = true;
      const { data } = await getPartyResources(this.parameter);
      this.contents = data;
      this.loading = false;
    },
    handleCurrentChange(page) {
      this.parameter.current = page;
      this.getPage();
    },
    handleSelect(item, index) {
      this.defaultItem = index;
      this.parameter.contentId = item.id;
      this.parameter.current = 1;
      this.getPage();
    },
    //banner详情
    toDetail(item) {
      if (item.link) {
        window.open(item.link);
      }
      if (item.resourcesId > 0) {
        this.$router.push({
          path: '/party/content',
          query: { cid: item.resourcesId },
        });
      }
    },
  },
};
</script>
<style
  lang="scss"
  scoped>
@font-face {
  font-family: Rajdhani;
  src: url('../../../assets/fonts/YouSheBiaoTiHei-2.ttf');
}

::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #dd2622;
}

.cotainer {
  .banner {
    width: 100%;
    margin: 20px 0;
  }

  padding-bottom: 40px;

  .paging {
    position: absolute;
    width: 100%;
    text-align: right;
    margin-top: 10px;
  }

  .box {
    > div {
      background-color: #fff;
      border-radius: 5px;
    }

    .tabs {
      width: 200px;

      .item {
        border-radius: 6px;
        padding: 10px;

        cursor: pointer;

        height: 60px;
        display: flex;
        align-items: center;

        .title {
          font-size: 18px;
          font-family: Rajdhani;
        }

        .total {
          font-size: 12px;
          opacity: 0.8;
        }
      }

      .active {
        background: url('../../../assets/image/Group 1020.png');
        color: #fff;
        background-size: 100% 100%;
      }
    }

    .contents {
      flex: 1;
      margin-left: 10px;
      position: relative;
      .list {
        min-height: 240px;
      }
      .list > div {
        margin: 18px 15px;
        display: flex;
        font-size: 14px;
        cursor: pointer;
        justify-content: space-between;
      }
    }
  }
}
</style>
